import { useNavigate } from "react-router-dom";
import { currencyFormat } from "../shared/helpers/functions";

const HomeProduct = ({vehicle}) => {

  let navigate = useNavigate();

  function handleClick(event) {
    event.preventDefault();
    // Add your custom logic or actions here
    navigate(`/detalji/`+ vehicle.id);
  }

  return (
    <a href={`/detalji/`+ vehicle.id} onClick={handleClick} className="home-product col-2">
      <div className={`price ${(vehicle?.status === "stock" && !vehicle?.in_arrival) ? "price-green" : vehicle?.status === "sold" ? "price-red" : (vehicle?.status === "offer" || vehicle?.in_arrival) ? "bg-warning" : ""}`}>
        {vehicle?.status === "stock" && vehicle?.in_arrival && <span>U dolasku</span>}
        {vehicle?.status === "stock" && !vehicle?.in_arrival && <span>Na stanju</span>}
        {vehicle?.status === "sold" && <span>Prodato</span>}
        {vehicle?.status === "offer" && <span>U dolasku</span>}
      </div>
      <img src={vehicle?.image_url} alt="logo"/>
      <div className="product-info-container">
        <h5 className="name">{vehicle?.full_name ?? ''}</h5>
        <p className="category">{vehicle?.category_name ?? ''}</p>
        <p className="product-year">{vehicle?.production_year > 1 ? vehicle?.production_year + ". godina" : '-'} </p>
        <h5 className="price-text">{vehicle?.show_price && vehicle?.price > 0 ? currencyFormat(vehicle?.price ?? 0) + " €" : 'Kontaktirajte nas'} <p> { vehicle?.show_price && vehicle?.price > 0 ? " + PDV" : ''}</p></h5>
        <p className="product-year">{(vehicle?.name ?? '').substring(2)}</p>
        <div className={`status-text ${(vehicle?.status === "stock" && !vehicle?.in_arrival) ? "price-green" : vehicle?.status === "sold" ? "price-red" : (vehicle?.status === "offer" || vehicle?.in_arrival) ? "bg-warning" : ""}`}>
        {vehicle?.status === "stock" && vehicle?.in_arrival && <span>U dolasku</span>}
        {vehicle?.status === "stock" && !vehicle?.in_arrival && <span>Na stanju</span>}
        {vehicle?.status === "sold" && <span>Prodato</span>}
        {vehicle?.status === "offer" && <span>U dolasku</span>}
        </div>
      </div>
    </a>
  );
}

export default HomeProduct;
