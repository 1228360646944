import React, { useState, useEffect, useRef } from "react";
import Gallery from 'react-image-gallery';

const ImagesSlider = ({ images }) => {

  // Images
  const [sliderImages, setSliderImages] = useState([]);
  const galleryRef = useRef(null);

  const onSlideClick = (e) => {
    console.log(e)
    galleryRef.current.fullScreen();
  }

  useEffect(() => {
    if (images && images.length > 0) {
      let data = [];
      for (let item of images) {
        data.push({ original: item.image_url, thumbnail: item.image_url });
      }
      setSliderImages(data);
    } else {
      setSliderImages([]);
    }
  }, [images]);

  return (
    <Gallery items={sliderImages} onClick={(e) => onSlideClick(e)} ref={galleryRef}  showPlayButton={false}/>
  );
};

export default ImagesSlider;
