import Select from "react-select";

const Input = (props) => {

    const inputElement = () => {
        const inputId = props.id ? props.id : Math.random();

        switch(props.inputType) {
            case "input":
                return <>
                    <input 
                        autoComplete={props.offAutoComplete ? "new-email" : ''}
                        value={props.inputValue} onKeyDown={props.onInputKeyDown}
                        onChange={props.onInputChange}
                        onBlur={props.onInputBlur}
                        id={inputId}
                        type={props.type ? props.type : 'text'}
                        className={props.class ? props.class : ''}
                        disabled={props.disabled}
                        placeholder={props.placeHolder ?? ""}
                    />
                </>
            case "text-input":
                return <>
                    <div className="input-group mt-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id={inputId}>{props.placeHolder ?? ''}</span>
                        </div>
                        <input
                            type={props.type ? props.type : 'text'}
                            className="form-control"
                            aria-describedby={inputId}
                            value={props.inputValue} onKeyDown={props.onInputKeyDown}
                            onChange={props.onInputChange}
                            onBlur={props.onInputBlur}
                        />
                    </div>
                </>
                case "textarea":
                    return <>
                        <div className="input-group mt-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id={inputId}>{props.placeHolder ?? ''}</span>
                            </div>
                            <textarea
                                className="form-control"
                                aria-describedby={inputId}
                                value={props.inputValue} onKeyDown={props.onInputKeyDown}
                                onChange={props.onInputChange}
                                onBlur={props.onInputBlur}
                                rows={4}
                            />
                        </div>
                    </>
            case "select-react":
                return <>
                    <Select
                        className={(props.class ? props.class : '') + " basic-single"}
                        classNamePrefix="select"
                        isDisabled={props.disabled}
                        placeholder={props.placeHolder ?? ""}
                        isClearable={props.isClearable ?? true}
                        isSearchable={props.isSearchable ?? true}
                        onInputChange={props.onInputBlur}
                        isMulti={props.isMulti}
                        value={props.data?.filter((obj) => obj.id === props.value || props.values?.includes(obj.id))}
                        options={props.data}
                        getOptionLabel={ (option) => (option.name || option.manufacturer_name || option.category_name || option.model_name)}
                        getOptionValue={ (option) => option.id}
                        onChange={props.handleChange}
                    />
                </>
            default: return <h1>No input type match!</h1>;
        }
    }

    return (
        inputElement()
    )

}

export default Input;