import React, { useEffect, useState, useRef, forwardRef } from "react";
import HomeProduct from "../components/HomeProduct";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesUp, faArrowCircleUp } from "@fortawesome/free-solid-svg-icons";
import AuthContext from "../shared/contexs/auth-contex";
import { useContext } from "react";

const HomePage = forwardRef((props, ref) => {
  const [vehicles, setVehicles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dataForFilter, setDataForFilter] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerRow, setItemsPerRow] = useState(6);
  const haveMoreRef = useRef(false); // Use useRef for mutable value
  const footerRef = useRef(null);
  const { user } = useContext(AuthContext);

  // Assign the child function to the ref passed from the parent
  React.useImperativeHandle(ref, () => ({
    getProducts: (dataForFilterCopy) => {
      // Call the child function with the provided data
      const dataCopy = JSON.parse(JSON.stringify(dataForFilterCopy));
      dataCopy.page = pageNumber;
      setPageNumber(1);
      setDataForFilter(dataCopy);
      getProducts(dataCopy, false);
      haveMoreRef.current = true;
    }
  }));

  const getProducts = async (data, changeData) => {
    const currentTime = new Date(new Date().getTime());
    const dataCopy = { ...data, page: changeData ? pageNumber : 1 };
    localStorage.setItem('lastHttp', currentTime);

    if (!user?.is_admin) {
      data.model_id = null;
      data.query = null;
    }

    try {
      setIsLoading(true);
      const response = await fetch(
        "https://api.bagi.rs/api/v1/list/vehicles",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${user?.access_token}`
          },
          body: JSON.stringify(dataCopy)
        }
      );

      if (!response.ok) {
        throw new Error(`Error updating data: ${response.status} ${response.statusText}`);
      }

      const updatedData = await response.json();
      if (updatedData?.data?.vehicles.length > 19) {
        haveMoreRef.current = true;
      } else {
        haveMoreRef.current = false;
      }
      if (changeData) {
        setVehicles(prevVehicles => [...prevVehicles, ...(updatedData?.data?.vehicles ?? [])]);      
      } else {
        setVehicles(updatedData?.data?.vehicles ?? []);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error.message);
      setIsLoading(false);
      // Show a user-friendly error message or notification here
    }
  };

  useEffect(() => {
    if (pageNumber === 1) {
      return;
    }
    getProducts(dataForFilter, true);
  }, [pageNumber]);

  useEffect(() => {
    let isFooterVisible = false;
  
    const handleScroll = () => {
      const footerRect = footerRef.current?.getBoundingClientRect();
      const isVisible = footerRect?.top <= window.innerHeight;
  
      if (isVisible && !isFooterVisible) {
        // Set the flag to prevent multiple calls
        isFooterVisible = true;
  
        // Update the page number
        if (haveMoreRef.current) {
          setPageNumber(prevPageNumber => prevPageNumber + 1);
        }
      } else if (!isVisible) {
        // Reset the flag when the footer is no longer visible
        isFooterVisible = false;
      }
    };
  
    const handleFooterVisibility = () => {
      handleScroll();
    };
  
    window.addEventListener("scroll", handleFooterVisibility);
  
    return () => {
      window.removeEventListener("scroll", handleFooterVisibility);
    };
  }, []);
  
  

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth < 320.01) {
        setItemsPerRow(1);
      }
      else if (window.innerWidth < 450.01) {
        setItemsPerRow(2);
      }
      else if (window.innerWidth < 992.1) {
        setItemsPerRow(3);
      }
      else if (window.innerWidth < 1200.1) {
        setItemsPerRow(4);
      }
      else if (window.innerWidth < 1400.1) {
        setItemsPerRow(5);
      } else {
        setItemsPerRow(6);
      }
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);


  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const threshold = 100; // Adjust this value to control when the button appears

      if (scrollTop > threshold) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="product">
      {isLoading && (
        <div id="pause" className="d-flex align-items-center justify-content-center">
          <div id="spinner"></div>
        </div>
      )}
      <div className="container-fluid ps-0">
        <div className="row m-0">
          {vehicles.length > 0 && 
            vehicles.map((vehicle, index) => {
              return (
                <React.Fragment key={vehicle.id}>
                  <HomeProduct vehicle={vehicle} />
                  {index === vehicles.length - 1 && (
                    <div className="end-of-list-marker" ref={footerRef} />
                  )}
                </React.Fragment>
              );
            })}
        </div>
      </div>
      {showScrollButton && vehicles.length > itemsPerRow && (
        <button onClick={handleScrollToTop} className="start-button scroll-mobile"><FontAwesomeIcon icon={faAnglesUp} /></button>
      )}
      {vehicles.length === 0 && !isLoading &&  (
        <div  className="row text-center m-0">
          <div className="col-12">
            <h5>Trenutno nema vozila za odabrane filtere!</h5>
          </div>
        </div>
      )}
      {vehicles.length > 0 && !isLoading && (
        <div className="row text-center m-0 scroll-desktop">
          <div className="col-12">
            <button onClick={handleScrollToTop} className="start-button"><FontAwesomeIcon icon={faArrowCircleUp} /></button>
          </div>
        </div>
      )}
    </div>
  );
});

export default HomePage;
