import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import './assets/css/common.scss';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { AuthContextProvider } from './shared/contexs/auth-contex';

const container = document.getElementById('root');

// Create a root.
const root = ReactDOMClient.createRoot(container);

root.render(
    <AuthContextProvider>
        <BrowserRouter basename={'/'}>
            <App />
        </BrowserRouter>
    </AuthContextProvider>
);
