import Input from "./Input";
import useInput from "../hooks/use-input";
import { useContext, useEffect, useState } from "react";
import { doesIdExist } from "../helpers/functions";
import { useLocation } from "react-router-dom";
import AuthContext from "../contexs/auth-contex";

const SubHeader = ({changeFilter}) => {

  const { user } = useContext(AuthContext);

  const sortList = [
    {
      name: "Najnovije",
      id: null
    },
    {
      name: "Cena",
      id: 'price_sort',
    },
    {
      name: "Kategorija",
      id: 'category_sort',
    }
  ];

  const statusList = [
    {
      name: "Ponuda",
      id: 'offer'
    },
    {
      name: "Kupovina u toku",
      id: 'purchase_in_progress',
    },
    {
      name: "Lager",
      id: 'stock',
    },
    {
      name: "Rezervisano",
      id: 'reserved',
    },
    {
      name: "Kapara",
      id: 'deposit',
    },
    {
      name: "Prodato",
      id: 'sold',
    }
  ];

  const showProductList = [
    {
      name: "Admin",
      id: 'admin'
    },
    {
      name: "Radnik",
      id: 'worker',
    }
  ];

  const [sortSelected, setSortSelected] = useState(null);
  const [yearsStart, setYearsStart] = useState([]);
  const [yearsEnd, setYearsEnd] = useState([]);
  const [priceStart, setPriceStart] = useState([]);
  const [priceEnd, setPriceEnd] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [manufacturersList, setManufacturersList] = useState([]);
  const [modelsList, setModelsList] = useState([]);
  const [productFilter, setProductFilter] = useState({
    category_id: null,
    manufacturer_id: null,
    sort: null,
    model_id: null,
    query: "",
    status: null,
    view: null
  });

  let {
    value: categoryValue,
    valueChangeHandler: categoryValueChangeHandler,
    reset: resetCategory
  } = useInput((value) => value);

  let {
    value: manufacturerValue,
    valueChangeHandler: manufacturerValueChangeHandler,
    reset: resetManufacturer
  } = useInput((value) => value);

  let {
    value: modelValue,
    valueChangeHandler: modelValueChangeHandler,
    reset: resetModel
  } = useInput((value) => value);

  let {
    value: statusValue,
    valueChangeHandler: statusValueChangeHandler,
    reset: resetStatus
  } = useInput((value) => value);

  let {
    value: showProductValue,
    valueChangeHandler: showProductValueChangeHandler,
    reset: resetShowProduct
  } = useInput((value) => value);

  const [search, setSearch] = useState('');

  let {
    value: priceStartValue,
    valueChangeHandler: priceStartValueChangeHandler
  } = useInput((value) => value);

  let {
    value: priceEndValue,
    valueChangeHandler: priceEndValueChangeHandler,
    reset: resetPriceEnd
  } = useInput((value) => value);

  let {
    value: yearStartValue,
    valueChangeHandler: yearStartValueChangeHandler
  } = useInput((value) => value);

  let {
    value: yearEndValue,
    valueChangeHandler: yearEndValueChangeHandler,
    reset: resetYearEnd
  } = useInput((value) => value);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      const data = JSON.parse(JSON.stringify(productFilter));
      if (data.query != search) {
        data.query = search;
        setProductFilter(data);
      }
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [search]);

  const sortChangeHandler = (ev) => {
    setSortSelected(ev.id);
    const data = JSON.parse(JSON.stringify(productFilter));
    data.sort = ev.id;
    setProductFilter(data);
  };

  const categoryChangeHandler = (ev) => {
    const valueInput = {target: {value : (ev && ev.id !== null) ? ev.id : ev}};
    categoryValueChangeHandler(valueInput);
    const data = JSON.parse(JSON.stringify(productFilter));
    data.category_id = (ev && ev.id !== null) ? ev.id : ev;
    getManufacturers(data);
  };

  const manufacturerChangeHandler = (ev) => {
    const valueInput = {target: {value : (ev && ev.id !== null) ? ev.id : ev}};
    manufacturerValueChangeHandler(valueInput);
    const data = JSON.parse(JSON.stringify(productFilter));
    data.manufacturer_id = (ev && ev.id !== null) ? ev.id : ev;
    data.model_id = null;
    getCategories(data);
    getModels(data, true);
    resetModel();
  };

  const modelChangeHandler = (ev) => {
    const valueInput = {target: {value : (ev && ev.id !== null) ? ev.id : ev}};
    modelValueChangeHandler(valueInput);
    const data = JSON.parse(JSON.stringify(productFilter));
    data.model_id = (ev && ev.id !== null) ? ev.id : ev;
    setProductFilter(data);
  };

  const statusChangeHandler = (ev) => {
    const valueInput = {target: {value : (ev && ev.id !== null) ? ev.id : ev}};
    statusValueChangeHandler(valueInput);
    const data = JSON.parse(JSON.stringify(productFilter));
    data.status = (ev && ev.id !== null) ? ev.id : ev;
    setProductFilter(data);
  };

  const showProductChangeHandler = (ev) => {
    const valueInput = {target: {value : (ev && ev.id !== null) ? ev.id : ev}};
    showProductValueChangeHandler(valueInput);
    const data = JSON.parse(JSON.stringify(productFilter));
    data.view = (ev && ev.id !== null) ? ev.id : ev;
    setProductFilter(data);
  };

  const yearStartChangeHandler = (ev) => {
    const valueInput = {target: {value : (ev && ev.id !== null) ? ev.id : ev}};
    const data = JSON.parse(JSON.stringify(productFilter));
    if (ev?.id) {
      const currentYear = new Date().getFullYear();
      const years = Array.from({ length: currentYear - ev.id + 1 }, (_, index) => {
        const year = currentYear - index;
        return { id: year, name: year.toString() };
      });
      setYearsEnd(years);
      if (yearEndValue && yearEndValue < ev.id) {
        resetYearEnd();
        data.year_to = null;
      }
    } else {
      setYearsEnd(yearsStart);
    }
    data.year_from = (ev && ev.id !== null) ? ev.id : ev;
    setProductFilter(data);
    yearStartValueChangeHandler(valueInput);
  };

  const yearEndChangeHandler = (ev) => {
    const valueInput = {target: {value : (ev && ev.id !== null) ? ev.id : ev}};
    yearEndValueChangeHandler(valueInput);
    const data = JSON.parse(JSON.stringify(productFilter));
    data.year_to = (ev && ev.id !== null) ? ev.id : ev;
    setProductFilter(data);
  };

  const priceStartChangeHandler = (ev) => {
    const valueInput = {target: {value : (ev && ev.id !== null) ? ev.id : ev}};
    const data = JSON.parse(JSON.stringify(productFilter));
    setProductFilter(data);
    if (ev?.id) {
      const filteredData = priceStart.filter(item => item.id >= ev.id);
      setPriceEnd(filteredData);       
      if (priceEndValue && priceEndValue < ev.id) {
        resetPriceEnd();
        data.price_to = null;
      }
    } else {
      setPriceEnd(priceStart);
    }
    data.price_from = (ev && ev.id !== null) ? ev.id : ev;
    setProductFilter(data);
    priceStartValueChangeHandler(valueInput);
  };

  const priceEndChangeHandler = (ev) => {
    const valueInput = {target: {value : (ev && ev.id !== null) ? ev.id : ev}};
    priceEndValueChangeHandler(valueInput);
    const data = JSON.parse(JSON.stringify(productFilter));
    data.price_to = (ev && ev.id !== null) ? ev.id : ev;
    setProductFilter(data);
  };

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: currentYear - 1959 }, (_, index) => {
      const year = currentYear - index;
      return { id: year, name: year.toString() };
    });
    setYearsStart(years);
    setYearsEnd(years);
  }, []);


  useEffect(() => {
    // getCategories({}, true);
    // getManufacturers({}, true);
  }, []);

  useEffect(() => {
  if (user?.is_admin) {
    getModels(productFilter, true);
  } else {
    setModelsList([]);
    const data = JSON.parse(JSON.stringify(productFilter));
    data.model_id = null;
    data.query = "";
    data.status = null;
    data.view = null;

    setProductFilter(data);
    setSearch('');
  }
    getManufacturers({}, true);
    resetModel();
  }, [user]);


  const getCategories = async (data, callProducts) => {
    const currentTime = new Date(new Date().getTime());
    localStorage.setItem('lastHttp', currentTime);
    try {
      const response = await fetch(
        "https://api.bagi.rs/api/v1/get/site/categories",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${user?.access_token}`
          },
          body: JSON.stringify(
            {
              manufacturer_id: data?.manufacturer_id ?? null
            }
          )
        }
      );

      if (!response.ok) {
        throw new Error("Error updating data");
      }

      const updatedData = await response.json();
      if (data?.category_id) {
        if (updatedData?.data?.categories?.length > 0) {
          if (doesIdExist(updatedData.data.categories, data.category_id)) {
            setProductFilter(data);
          } else {
            data.category_id = null;
            resetCategory();
            setProductFilter(data);
          }
        } else {
          data.category_id = null;
          resetCategory();
          setProductFilter(data);
        }
      } else if (!callProducts) {
        setProductFilter(data);
      }
      setCategoriesList(updatedData?.data?.categories ?? []);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getManufacturers = async (data, callProducts) => {
    const currentTime = new Date(new Date().getTime());
    localStorage.setItem('lastHttp', currentTime);
    try {
      const response = await fetch(
        "https://api.bagi.rs/api/v1/get/site/manufacturers",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${user?.access_token}`
          },
          body: JSON.stringify(
            {
              category_id: data?.category_id ?? null
            }
          )
        }
      );

      if (!response.ok) {
        throw new Error("Error updating data");
      }

      const updatedData = await response.json();
      if (data?.manufacturer_id) {
        if (updatedData?.data?.manufacturers?.length > 0) {
          if (doesIdExist(updatedData.data.manufacturers, data.manufacturer_id)) {
            setProductFilter(data);
          } else {
            data.manufacturer_id = null;
            resetManufacturer();
            setProductFilter(data);
          }
        } else {
          data.manufacturer_id = null;
          resetManufacturer();
          setProductFilter(data);
        }
      } else if (!callProducts) {
        setProductFilter(data);
      }
      setManufacturersList(updatedData?.data?.manufacturers ?? []);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getModels = async (data, callProducts) => {
    const currentTime = new Date(new Date().getTime());
    localStorage.setItem('lastHttp', currentTime);
    try {
      const response = await fetch(
        "https://api.bagi.rs/api/v1/get/site/models",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${user?.access_token}`
          },
          body: JSON.stringify(
            {
              manufacturer_id: data?.manufacturer_id ?? null
            }
          )
        }
      );

      if (!response.ok) {
        throw new Error("Error updating data");
      }

      const updatedData = await response.json();
      // if (data?.category_id) {
      //   if (updatedData?.data?.categories?.length > 0) {
      //     if (doesIdExist(updatedData.data.categories, data.category_id)) {
      //       setProductFilter(data);
      //     } else {
      //       data.category_id = null;
      //       resetCategory();
      //       setProductFilter(data);
      //     }
      //   } else {
      //     data.category_id = null;
      //     resetCategory();
      //     setProductFilter(data);
      //   }
      // } else if (!callProducts) {
      // }
      setProductFilter(data);
      setModelsList(updatedData?.data?.models ?? []);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    const data = [
      { id: 5000, name: "5.000,00" },
      { id: 10000, name: "10.000,00" },
      { id: 20000, name: "20.000,00" },
      { id: 30000, name: "30.000,00" },
      { id: 40000, name: "40.000,00" },
      { id: 50000, name: "50.000,00" },
      { id: 60000, name: "60.000,00" },
      { id: 70000, name: "70.000,00" },
      { id: 80000, name: "80.000,00" },
      { id: 90000, name: "90.000,00" },
      { id: 100000, name: "100.000,00" },
      { id: 125000, name: "125.000,00" },
      { id: 150000, name: "150.000,00" },
      { id: 200000, name: "200.000,00" }
    ];          
    setPriceStart(data);
    setPriceEnd(data);
  }, []);

  const location = useLocation();
  const [lastLocation, setLastLocation] = useState(null);

  useEffect(() => {
    changeFilter(productFilter);
  }, [productFilter]);

  useEffect(() => {
    setLastLocation(location.pathname);

    const navLinkClicked = sessionStorage.getItem('navLinkClicked') === 'true';
    sessionStorage.removeItem('navLinkClicked');

    if (lastLocation && location.pathname === "/" && lastLocation !== "/") {
      if (navLinkClicked) {
        resetFilters();
      } else {
        changeFilter(productFilter);
      }
    } else {
      if (!location.pathname.includes('detalji')) {
        resetFilters();
      }
    }
    
  }, [location]);

  const resetFilters = () => {
    const data = JSON.parse(JSON.stringify(productFilter));
    data.model_id = null;
    data.query = "";
    data.status = null;
    data.view = null;
    data.manufacturer_id = null;
    data.category_id = null;
    data.sort = null;
    resetCategory();
    resetModel();
    resetManufacturer();
    resetStatus();
    resetShowProduct();
    setSortSelected(null);
    setSearch('');

    getCategories(data);
    getManufacturers(data);
    if (user?.is_admin) {
      getModels(data);
    }
    setProductFilter(data);
  }

  return (
    <div className={ location.pathname === '/' ? "sub-header" : "d-none" }>
        <div className="container-fluid">
            <div className="me-auto subheader-filters col-7">
                {location.pathname === '/' && (
                  <Input
                    value={categoryValue}
                    isMulti={false}
                    handleChange={categoryChangeHandler}
                    disabled={false}
                    data={categoriesList}
                    inputType="select-react"
                    type="text"
                    placeHolder="Kategorija"
                    class="basic-single col-4"
                  />
                )}
                {location.pathname === '/' && (
                  <div className="filter-line"><span></span></div>
                )}
                {location.pathname === '/' && (
                  <Input
                    value={manufacturerValue}
                    isMulti={false}
                    handleChange={manufacturerChangeHandler}
                    disabled={false}
                    data={manufacturersList}
                    inputType="select-react"
                    type="text"
                    placeHolder="Proizvođač"
                    class="basic-single col-4"
                  />
                )}
                {location.pathname === '/' && (
                  <div className="filter-line desktop-filter"><span></span></div>
                )}
                {/* {location.pathname === '/' && user?.is_admin && (
                  <Input
                    value={statusValue}
                    isMulti={false}
                    handleChange={statusChangeHandler}
                    disabled={false}
                    data={statusList}
                    inputType="select-react"
                    type="text"
                    placeHolder="Status"
                    class="basic-single col-4 desktop-filter"
                  />
                )} */}
                {location.pathname === '/' && (
                  <input
                    onChange={ (e) => { setSearch(e.target.value) }}
                    type="text"
                    className="basic-single basic-input col-4 desktop-filter"
                    id="searchInput"
                    aria-describedby="search"
                    placeholder="Broj tablice"
                  />
                )}
                {/* <Input
                  isMulti={false}
                  disabled={false}
                  data={[]}
                  inputType="select-react"
                  type="text"
                  placeHolder="Sortiraj po"
                  class="basic-single col-4 sort-select"
                  isClearable={false}
                /> */}
                {/* <Input
                  value={priceStartValue}
                  isMulti={false}
                  handleChange={priceStartChangeHandler}
                  disabled={false}
                  data={priceStart}
                  inputType="select-react"
                  type="text"
                  placeHolder="Cena od"
                  class="basic-single col-3"
                />
                <Input
                  value={priceEndValue}
                  isMulti={false}
                  handleChange={priceEndChangeHandler}
                  disabled={false}
                  data={priceEnd}
                  inputType="select-react"
                  type="text"
                  placeHolder="Cena do"
                  class="basic-single col-3"
                />
                <Input
                  value={yearStartValue}
                  isMulti={false}
                  handleChange={yearStartChangeHandler}
                  disabled={false}
                  data={yearsStart}
                  inputType="select-react"
                  type="text"
                  placeHolder="Godiste od"
                  class="basic-single col-3"
                />
                <Input
                  value={yearEndValue}
                  isMulti={false}
                  handleChange={yearEndChangeHandler}
                  disabled={false}
                  data={yearsEnd}
                  inputType="select-react"
                  type="text"
                  placeHolder="Godiste do"
                  class="basic-single col-3"
                /> */}
            </div>
            {location.pathname === '/' && (
              <Input
                value={sortSelected}
                isMulti={false}
                handleChange={sortChangeHandler}
                disabled={false}
                data={sortList}
                inputType="select-react"
                type="text"
                placeHolder="Sortiranje"
                class="basic-single sort-select desktop-sort"
                isClearable={false}
                isSearchable={false}
              />
            )}
            <div className="me-auto subheader-filters col-7 pt-0 mobile-filter">
                {/* {location.pathname === '/' && user?.is_admin && (
                  <Input
                    value={statusValue}
                    isMulti={false}
                    handleChange={statusChangeHandler}
                    disabled={false}
                    data={statusList}
                    inputType="select-react"
                    type="text"
                    placeHolder="Status"
                    class="basic-single col-4"
                  />
                )} */}
                {location.pathname === '/' && (
                  <input
                    onChange={ (e) => { setSearch(e.target.value) }}
                    type="text"
                    className="basic-single basic-input col-4"
                    id="searchInput"
                    aria-describedby="search"
                    placeholder="Broj tablice"
                    style={{height: 38}}
                  />
                )}
                {location.pathname === '/' && (
                  <div className="filter-line"><span></span></div>
                )}
                {location.pathname === '/' && user?.is_admin && (
                  <Input
                    value={showProductValue}
                    isMulti={false}
                    handleChange={showProductChangeHandler}
                    disabled={false}
                    data={showProductList}
                    inputType="select-react"
                    type="text"
                    placeHolder="Prikaz vozila"
                    class="basic-single col-4"
                  />
                )}
            </div>
            {location.pathname === '/' && user?.is_admin && (
            <div className="me-auto subheader-filters col-7 pt-0">
                {location.pathname === '/' && user?.is_admin && (
                  <Input
                    value={modelValue}
                    isMulti={false}
                    handleChange={modelChangeHandler}
                    disabled={false}
                    data={modelsList}
                    inputType="select-react"
                    type="text"
                    placeHolder="Model"
                    class="basic-single col-4"
                  />
                )}
                {location.pathname === '/' && user?.is_admin && (
                  <div className="filter-line"><span></span></div>
                )}
                {/* {location.pathname === '/' && user?.is_admin && (
                  <input
                    onChange={ (e) => { setSearch(e.target.value) }}
                    type="text"
                    className="basic-single basic-input col-4"
                    id="searchInput"
                    aria-describedby="search"
                    placeholder="Broj tablice"
                  />
                )} */}
                {location.pathname === '/' && user?.is_admin && (
                  <Input
                    value={statusValue}
                    isMulti={false}
                    handleChange={statusChangeHandler}
                    disabled={false}
                    data={statusList}
                    inputType="select-react"
                    type="text"
                    placeHolder="Status"
                    class="basic-single col-4"
                  />
                )}
                {location.pathname === '/' && user?.is_admin && (
                  <div className="filter-line"><span></span></div>
                )}
                {location.pathname === '/' && user?.is_admin && (
                  <Input
                    value={showProductValue}
                    isMulti={false}
                    handleChange={showProductChangeHandler}
                    disabled={false}
                    data={showProductList}
                    inputType="select-react"
                    type="text"
                    placeHolder="Prikaz vozila"
                    class="basic-single col-4 desktop-filter"
                  />
                )}
            </div>
            )}

            {location.pathname === '/' && (
              <Input
                value={sortSelected}
                isMulti={false}
                handleChange={sortChangeHandler}
                disabled={false}
                data={sortList}
                inputType="select-react"
                type="text"
                placeHolder="Sortiranje"
                class="basic-single sort-select mobile-sort"
                isClearable={false}
                isSearchable={false}
              />
            )}
            {/* <div className="mobile-filters-row subheader-filters">
              <Input
                value={categoryValue}
                isMulti={false}
                handleChange={categoryChangeHandler}
                disabled={false}
                data={categoriesList}
                inputType="select-react"
                type="text"
                placeHolder="Kategorija"
                class="basic-single col-4 first-filter"
              />
              <Input
                value={manufacturerValue}
                isMulti={false}
                handleChange={manufacturerChangeHandler}
                disabled={false}
                data={manufacturersList}
                inputType="select-react"
                type="text"
                placeHolder="Proizvođač"
                class="basic-single col-6"
              />
            </div> */}
        </div>
    </div>
  );
}

export default SubHeader;
