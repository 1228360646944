import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faGlobe, faPeopleGroup, faFlagCheckered, faFileLines, faPhone, faMapPin, faUser, faBars, faUserLock, faLocation, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faInstagram, faViber, faYoutube } from "@fortawesome/free-brands-svg-icons";
import logo from "../../assets/images/logo.png";
import { useState } from "react";
import LoginRegisterModal from '../../components/LoginRegisterModal';
import { useContext } from 'react';
import AuthContext from '../contexs/auth-contex';
import { toast } from 'react-toastify';

const Header = () => {

  const [openMobileNav, setOpenMobileNav] = useState(false); 
  const [showModal, setShowModal] = useState(false);

  const { logout, isLoggedIn, user } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const logoutHandler = async (event) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        "https://api.bagi.rs/api/v1/bagi-backoffice/logout",
        {
          method: "PUT",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${user.access_token}`
          }
        }
      );

      let updatedData = await response.json();

      if (updatedData.is_error) {
        throw new Error(updatedData.message ?? 'Greška prilikom odave!');
      }

      toast.success(updatedData.message ?? 'Uspešno ste se odjavili!');
      setIsLoading(false);
      logout();
      
    } catch (error) {
      setIsLoading(false);
      toast.warning(error.message || 'Something went wrong!');
    }
  };

  const handleNavLinkClick = (event) => {
    sessionStorage.setItem('navLinkClicked', true);
  };

  return (
    <>
      <nav className="navbar navbar-expand-md navbar-light sticky-top desktop">
        <div className="container-fluid">
          <div className="logo-container">
            <NavLink onClick={handleNavLinkClick}className="navbar-brand" to="/">
              <img src={logo} alt="logo"/>
            </NavLink>
            <div className="phone-header">
              <div>
                <a href="tel:+381 69 800 31 28"> +381 69 800 31 28</a>
                <a href="tel:+381 69 847 64 64"> +381 69 847 64 64</a>
              </div>
              <a className="header-adrees" href="https://goo.gl/maps/vQncqWRN5vvGe7MN7" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faLocationDot} />
              </a>
            </div>
          </div>
          <div className="collapse navbar-collapse" id="navbar4">
            <ul className="navbar-nav ms-auto align-items-center">
              <li className="nav-item"><NavLink onClick={handleNavLinkClick}activeclassname="active" to="/"><FontAwesomeIcon icon={faHome} />Početna</NavLink></li>
              <li className="nav-item"><NavLink onClick={handleNavLinkClick}activeclassname="active" to="/bagis"><FontAwesomeIcon icon={faGlobe} />BAGIS</NavLink></li>
              <li className="nav-item"><NavLink onClick={handleNavLinkClick}activeclassname="active" to="/o-nama"><FontAwesomeIcon icon={faPeopleGroup} />O nama</NavLink></li>
              <li className="nav-item"><NavLink onClick={handleNavLinkClick}activeclassname="active" to="/sport"><FontAwesomeIcon icon={faFlagCheckered} />Sport</NavLink></li>
              <li className="nav-item"><NavLink onClick={handleNavLinkClick}activeclassname="active" to="/dokumenta"><FontAwesomeIcon icon={faFileLines} />Dokumenta</NavLink></li>
              <li className="nav-item"><NavLink onClick={handleNavLinkClick}activeclassname="active" to="/kontakt"><FontAwesomeIcon icon={faViber} />Kontakt</NavLink></li>
              <li className="nav-item">
              {!isLoggedIn && (
                <button className="login-button" onClick={() => setShowModal(true)}>
                  <FontAwesomeIcon icon={faUserLock} />
                  Prijavi se
                </button>
              )}
              {isLoggedIn && (
                <button className="login-button" onClick={logoutHandler}>
                  <FontAwesomeIcon icon={faUserLock} />
                  Odjavi se
                </button>
              )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="mobile top-navbar sticky-top">
        <div className="container-fluid">
          <NavLink onClick={handleNavLinkClick}className="navbar-brand" to="/">
            <img src={logo} alt="logo"/>
          </NavLink>
          <div className="navbar-icons">
            <a href="tel:+381 69 800 31 28" className="navbar-icon" type="button">
              <FontAwesomeIcon icon={faPhone} />
            </a>
            <a href="https://goo.gl/maps/RF7AaBKzk6cDAnDt7" target="_blank" rel="noreferrer" className="navbar-icon" type="button">
              <FontAwesomeIcon icon={faMapPin} />
            </a>
            {!isLoggedIn && (
              <button className="navbar-icon" type="button" onClick={() => setShowModal(true)}>
                <FontAwesomeIcon icon={faUser} />
              </button>
            )}
            {isLoggedIn && (
              <button className="navbar-icon" type="button" onClick={logoutHandler}>
                <FontAwesomeIcon icon={faUser} />
              </button>
            )}
            <button className="navbar-icon" type="button" onClick={ () => (setOpenMobileNav(!openMobileNav)) }>
              <FontAwesomeIcon icon={faBars} />
            </button>
          </div>
        </div>
      </div>
      <div className={`mobile nav-pages ${openMobileNav ? 'open' : ''}`}>
        <ul className="navbar-nav ms-auto">
          <li className="nav-item"><NavLink activeclassname="active" onClick={(e) => { e.stopPropagation(); setOpenMobileNav(!openMobileNav); handleNavLinkClick(); }} to="/"><FontAwesomeIcon icon={faHome} />Početna</NavLink></li>
          <li className="nav-item"><NavLink activeclassname="active" onClick={(e) => { e.stopPropagation(); setOpenMobileNav(!openMobileNav); handleNavLinkClick(); }} to="/bagis"><FontAwesomeIcon icon={faGlobe} />BAGIS</NavLink></li>
          <li className="nav-item"><NavLink activeclassname="active" onClick={(e) => { e.stopPropagation(); setOpenMobileNav(!openMobileNav); handleNavLinkClick(); }} to="/o-nama"><FontAwesomeIcon icon={faPeopleGroup} />O nama</NavLink></li>
          <li className="nav-item"><NavLink activeclassname="active" onClick={(e) => { e.stopPropagation(); setOpenMobileNav(!openMobileNav); handleNavLinkClick(); }} to="/sport"><FontAwesomeIcon icon={faFlagCheckered} />Sport</NavLink></li>
          <li className="nav-item"><NavLink activeclassname="active" onClick={(e) => { e.stopPropagation(); setOpenMobileNav(!openMobileNav); handleNavLinkClick(); }} to="/dokumenta"><FontAwesomeIcon icon={faFileLines} />Dokumenta</NavLink></li>
          <li className="nav-item"><NavLink activeclassname="active" onClick={(e) => { e.stopPropagation(); setOpenMobileNav(!openMobileNav); handleNavLinkClick(); }} to="/kontakt"><FontAwesomeIcon icon={faViber} />Kontakt</NavLink></li>
          <div className="nav-item nav-item-social">
            <a onClick={(e) => { e.stopPropagation(); setOpenMobileNav(!openMobileNav); }} className="facebook" href="https://www.facebook.com/bagitrans/?locale=sr_RS" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFacebook} /></a>
            <a onClick={(e) => { e.stopPropagation(); setOpenMobileNav(!openMobileNav); }} className="instagram" href="https://www.instagram.com/bagi.trans/?hl=en https://www.facebook.com/bagitrans/?locale=sr_RS " target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram} /></a>
            <a onClick={(e) => { e.stopPropagation(); setOpenMobileNav(!openMobileNav); }} className="youtube" href="https://www.youtube.com/@dejanlazarevic6509" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faYoutube} /></a>
          </div>
        </ul>
      </div>
      {openMobileNav && <div className="mobile-overlay" onClick={ () => (setOpenMobileNav(!openMobileNav)) }></div>}
      <LoginRegisterModal
        openModal={showModal}
        handleClose={() => {setShowModal(false); setShowModal(false)}}
      />
      {isLoading && (
        <div id="pause" className="d-flex align-items-center justify-content-center">
          <div id="spinner"></div>
        </div>
      )}
    </>
  );
}

export default Header;
