import { useEffect, useState } from "react";
import aboutusImg from "../assets/images/aboutus.png";

const AboutUsPage = () => {

    const [content, setContent] = useState('');

    const getContent = async () => {
        const currentTime = new Date(new Date().getTime());
        localStorage.setItem('lastHttp', currentTime);
        try {
          const response = await fetch(
            "https://api.bagi.rs/api/v1/get/page/content",
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(
                    {
                        page: 'about'
                    }
                )
            }
        );
    
        if (!response.ok) {
            throw new Error("Error updating data");
        }
    
          let updatedData = await response.json();
          setContent(updatedData?.data?.content ?? {});
        } catch (error) {
          console.log(error.message);
        }
    };

    useEffect(() => {
        getContent();
    }, []);

    return (
        <div className="about-us-page">
            <div className="about-image">
                <img className="img-fluid" src={aboutusImg} alt="about us"></img>
                {/* <h1>Prodaja mašina, alata i vozila</h1> */}
            </div>
            <div className="col-8 m-auto mt-5 mb-5">
                <div dangerouslySetInnerHTML={{ __html: content }} />
                {/* <h5>
                    "BAGI TRANS" već 28 godine uspešno snabdeva domaće i inostrano tržište polovnim i novim građevinskim
                    mašinama, rezervnim delovima kao i stručnim konsaltingom vezanim za oblast građevinarstva.
                </h5>
                <div className="mt-3"></div>
                Preko hiljadu zadovoljnih kupaca, zadovoljstvo nam je napisati, permanentno i konstantno sarađuje sa nama prilikom nabavke ili servisiranja građevinske mehanizacije.
                Permanentnom obukom, poslovnim rešenjima i primenom najnovijih znanja iz oblasti građevinske operative firma nastoji da unapredi svoje poslovanje i poslovanje svojih
                klijenata."BAGI TRANS" je osnovan 1990. godine pod prvobitnim nazivom "BAGGY GRAND LINE sa namerom da se bavi export-import poslovima sa robom široke
                potrošnje. Sa početkom ratnih dešavanja na prostorima bivše SFRJ, preduzeće privremeno prestaje sa radom i svoju delatnost nastavlja u Italiji bavivši se prvenstveno
                međunarodnim transportom, prodajom teretnih vozila i građevinskih mašina.
                <div className="mt-3"></div>
                Godine 1996. ponovo se seli u Srbiju, gde nastavlja da se bavi međunarodnim transportom, prodajom teretnih vozila, radnih i građevinskih mašina. Do 2001. godine
                posredovali smo u prodaji preko 500 teretnih vozila I mašina.
                <div className="mt-3"></div>
                Septembra 2001. godine vrši se reorganizacija preduzeća, menja ime u "BAGI TRANS d.o.o. sa sedištem u Kraljevu, počinje da se bavi i međunarodnom špedicijom,
                posredovanjem u carinskom postupku i spoljno trgovinskom prometu. Godine 2002. u saradnji sa partnerskim firmama, počinje da se bavi uvozom polovnih i oštećenih
                motornih vozila iz Belgije i Italije.<br/>
                Na ulazu u Kraljevo iz pravca Čačka u mestu Adrani preduzeće poseduje plac za prodaju pomenutih vozila i mašina na površini od jednog hektara, gde se radnim danima
                u vremenu od 08 do 17 časova mogu pogledati sva vozila i mašine, namenjena prodaji u prijatnom ambijentu i uz stručnu pomoć prodavaca.
                <div className="mt-3"></div>
                "BAGI TRANS " je deo Evropskog sistema sa najbržom mogućom isporukom i najdelikatnijih rezernih delova i opreme. Jedan od tih lanaca je prošle godine proglašen za
                najbržeg isporučioca na svetu, a čiji smo, sa ponosom možemo da napišemo, generalni predstavnici za Srbiju i istočnu Evropu. Kreirali smo i neprestano unapređujemo
                sistem koji obezbeđuje funkcionalan način saniranja svih vrsta kvarova koji našim klijentima pruža sigurnost i pouzdanost u obavljanu svoje delatnosti. Posedujemo
                ugovore sa najmoćnijim lizing kućama u Evropi, sopstvene kamione za prevoz mašina i opreme i sopstvene carinske agente čime smo dodatno unapredili svoje
                poslovanje i poslovanje klijenata sa kojima sarađujemo. */}
            </div>
        </div>
    );
}

export default AboutUsPage;
