import React, { useContext, useEffect, useRef } from 'react';
import HomePage from './pages/HomePage';
import { Route, Routes, useLocation } from 'react-router-dom';
import ProductDetailsPage from './pages/ProductDetailsPage';
import AboutUsPage from './pages/AboutUsPage';
import Footer from './shared/components/Footer';
import Header from './shared/components/Header';
import SubHeader from './shared/components/SubHeader';
import SportPage from './pages/SportPage';
import ContactPage from './pages/ContactPage';
import AuthContext from './shared/contexs/auth-contex';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import jwt_decode from "jwt-decode";
import BagisPage from './pages/BagisPage';
import DocumentsPage from './pages/DocumentsPage';
import NotFoundPage from './pages/NotFoundPage';

function App() {

  const authCtx = useContext(AuthContext);
  const homePageRef = useRef(null);
  const location = useLocation();

  const handleButtonClick = (dataForFilter) => {
    // Call the child function with the provided data
    homePageRef?.current?.getProducts(dataForFilter);
  };

  useEffect(() => {
    if (authCtx.isTokenExpired) {
      toast.warning('Token je istekao!');
      authCtx.changeTokenExpired(false);
    }
  }, [authCtx.isTokenExpired]);

  useEffect(() => {
    if (authCtx.isRefreshingToken) {

      const setUserData = (userData) => {
        const decoded = jwt_decode(userData.access_token);
        const expirationTime = new Date(decoded.exp * 1000);

        authCtx.login(userData, expirationTime);
      };

      const refreshToken = async () => {
        const currentTime = new Date(new Date().getTime());
        localStorage.setItem('lastHttp', currentTime);
        try {
          const response = await fetch(
            "https://api.bagi.rs/api/v1/bagi-backoffice/refresh",
            {
              method: "PUT",
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authCtx.user.access_token}`
              }
            }
          );
    
          if (!response.ok) {
            throw new Error("Greška prilikom refreša tokena");
          }
    
          let updatedData = await response.json();
          setUserData(updatedData?.data ?? {});
          
        } catch (error) {
          console.log(error.message);
        }
      };
  
      refreshToken();

    }
  }, [authCtx.isRefreshingToken]);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Header />
      <SubHeader changeFilter={(dataForFilter) => { handleButtonClick(dataForFilter); }} />
      <Routes>
        <Route path='/' element={<HomePage ref={homePageRef} />} />
        <Route path="/detalji/:prdId" element={<ProductDetailsPage />} />
        <Route path="/o-nama" element={<AboutUsPage />} />
        <Route path="/sport" element={<SportPage />} />
        <Route path="/kontakt" element={<ContactPage />} />
        <Route path="/bagis" element={<BagisPage />} />
        <Route path="/dokumenta" element={<DocumentsPage />} />
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
      <Footer />
      <ToastContainer theme="colored" position="top-right" autoClose={3000}/>
    </>
  );
}

export default App;
