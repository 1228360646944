import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../assets/images/logo.png";
import { faFacebook, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";

const Footer = () => {

  return (
    <div className="footer">
        <div className="container-fluid desktop">
            <div className="col-2">
                <img src={logo} alt="logo" className="logo"/>
            </div>
            <div className="row col-10 footer-navs">
                <div className="footer-nav">
                    <h5>Početna</h5>
                    <div className="line"></div>
                    <ul className="nav flex-column">
                    </ul>
                </div>
                <div className="footer-nav">
                    <h5>BAGIS</h5>
                    <div className="line"></div>
                    <ul className="nav flex-column">
                    </ul>
                </div>
                <div className="footer-nav">
                    <h5>O nama</h5>
                    <div className="line"></div>
                    <ul className="nav flex-column">
                    </ul>
                </div>
                <div className="footer-nav">
                    <h5>Sport</h5>
                    <div className="line"></div>
                    <ul className="nav flex-column">
                    </ul>
                </div>
                <div className="footer-nav">
                    <h5>Dokumenta</h5>
                    <div className="line"></div>
                    <ul className="nav flex-column">
                    </ul>
                </div>
                <div className="footer-nav">
                    <h5>Kontakt</h5>
                    <div className="line"></div>
                    <ul className="nav flex-column">
                    </ul>
                </div>
            </div>
        </div>
        <div className="container-fluid pt-4 desktop">
            <div className="col-2 text-center">
                <a href="https://goo.gl/maps/vQncqWRN5vvGe7MN7" target="_blank" rel="noreferrer">
                    <p><b>Bagi Trans d.o.o.</b></p>
                    <p><b>Nikole Tesle 165A</b></p>
                    <p>36000 Kraljevo</p>
                    <p>Srbija</p>
                </a>
                <p className="mt-2"><a href="tel:+381 69 800 31 28">+381 69 800 31 28</a></p>
                <p className="mb-2"><a href="tel:+381 69 847 64 64"> +381 69 847 64 64</a></p>
                <p><a href="mailto:info@bagi.rs">info@bagi.rs</a></p>
            </div>
            <div className="col-10 subscribe-form">
                <a className="facebook" href="https://www.facebook.com/bagitrans/?locale=sr_RS" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFacebook} /></a>
                <a className="instagram" href="https://www.instagram.com/bagi.trans/?hl=en https://www.facebook.com/bagitrans/?locale=sr_RS " target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram} /></a>
                <a className="youtube" href="https://www.youtube.com/@dejanlazarevic6509" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faYoutube} /></a>
            </div>
        </div>
        <div className="container-fluid mobile mobile-navs">
            <div className="row col-12 footer-navs">
                <div className="footer-nav">
                    <h5>Početna</h5>
                    <div className="line"></div>
                    <ul className="nav flex-column">
                    </ul>
                </div>
                <div className="footer-nav">
                    <h5>BAGIS</h5>
                    <div className="line"></div>
                    <ul className="nav flex-column">
                    </ul>
                </div>
                <div className="footer-nav">
                    <h5>O nama</h5>
                    <div className="line"></div>
                    <ul className="nav flex-column">
                    </ul>
                </div>
                <div className="footer-nav">
                    <h5>Sport</h5>
                    <div className="line"></div>
                    <ul className="nav flex-column">
                    </ul>
                </div>
                <div className="footer-nav">
                    <h5>Dokumenta</h5>
                    <div className="line"></div>
                    <ul className="nav flex-column">
                    </ul>
                </div>
                <div className="footer-nav">
                    <h5>Kontakt</h5>
                    <div className="line"></div>
                    <ul className="nav flex-column">
                    </ul>
                </div>
            </div>
        </div>
        <div className="container-fluid mobile mobile-info">
            <div className="col-3">
                <img src={logo} alt="logo" className="logo"/>
            </div>
            <div className="col-5 ps-2">
                <div>
                    <a href="https://goo.gl/maps/vQncqWRN5vvGe7MN7" target="_blank" rel="noreferrer">
                        <p><b>Bagi Trans d.o.o.</b></p>
                        <p><b>Nikole Tesle 165A</b></p>
                        <p>36000 Kraljevo</p>
                        <p>Srbija</p>
                    </a>
                    <p className="mt-2"><a href="tel:+381 69 800 31 28">+381 69 800 31 28</a></p>
                    <p className="mb-2"><a href="tel:+381 69 847 64 64"> +381 69 847 64 64</a></p>
                    <p><a href="mailto:info@bagi.rs">info@bagi.rs</a></p>
                </div>
            </div>
            <div className="subscribe-form col-4">
                <a className="facebook" href="https://www.facebook.com/bagitrans/?locale=sr_RS" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFacebook} /></a>
                <a className="instagram" href="https://www.instagram.com/bagi.trans/?hl=en https://www.facebook.com/bagitrans/?locale=sr_RS " target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram} /></a>
                <a className="youtube" href="https://www.youtube.com/@dejanlazarevic6509" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faYoutube} /></a>
            </div>
        </div>
    </div>
  );
}

export default Footer;
