import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import sportImg from "../assets/images/sport.png";
import { faTrophy } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useEffect } from "react";

const SportPage = () => {

    const [content, setContent] = useState('');

    const getContent = async () => {
        const currentTime = new Date(new Date().getTime());
        localStorage.setItem('lastHttp', currentTime);
        try {
          const response = await fetch(
            "https://api.bagi.rs/api/v1/get/page/content",
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(
                    {
                        page: 'sport'
                    }
                )
            }
        );
    
        if (!response.ok) {
            throw new Error("Error updating data");
        }
    
          let updatedData = await response.json();
          setContent(updatedData?.data?.content ?? {});
        } catch (error) {
          console.log(error.message);
        }
    };

    useEffect(() => {
        getContent();
    }, []);

    return (
        <div className="about-us-page">
            <div className="about-image">
                <img className="img-fluid" src={sportImg} alt="sport"></img>
            </div>
            <div className="col-8 m-auto mt-5 mb-5">
                <div dangerouslySetInnerHTML={{ __html: content }} />
                {/* Beep Beep Racing Team je renomirana trkačka ekipa koja je osnovana od strane kompanije Bagi d.o.o., vodeće organizacije u automobilskoj industriji. Ova ekipa je
                postala sinonim za vrhunski trkački sport i predstavlja jedan od najpriznatijih timova u svetu trka.
                <div className="mt-3"></div>
                Beep Beep Racing Team je stekao svoj status lidera zahvaljujući svojoj strasti prema trkama, vrhunskim vozačima i kontinuiranom ulaganju u razvoj inovativnih
                tehnologija. Tim se sastoji od izuzetno talentovanih i iskusnih vozača, inženjera i tehničara koji zajednički rade kako bi postigli vrhunske rezultate u trkama.
                <div className="mt-3"></div>
                Ova ekipa je uspešno učestvovala u brojnim prestižnim trkačkim šampionatima širom sveta, osvajajući mnoge titule i postavljajući nove standarde u trkačkom sportu.
                Njihovi vozači su poznati po svom profesionalizmu, veštinama upravljanja vozilima i hrabrosti na stazi.
                <div className="mt-3"></div>
                Beep Beep Racing Team se ističe ne samo po svojim sportskim postignućima, već i po svom angažmanu u razvoju održive trkačke tehnologije. Ekipa aktivno radi na
                smanjenju ekološkog uticaja trkačkih vozila, istraživanju alternativnih izvora goriva i primeni novih tehnologija koje promovišu čistu energiju.
                <div className="mt-3"></div>
                Pored toga, Beep Beep Racing Team je takođe poznat po svojim humanitarnim naporima. Kroz različite inicijative, ekipa podržava lokalne zajednice, promoviše sigurnost
                u saobraćaju i pomaže u obrazovanju mladih vozača o trkačkim veštinama i odgovornom voženju.
                <div className="mt-3"></div>
                Svojim liderstvom u trkačkom sportu, Beep Beep Racing Team inspiriše ljude širom sveta da se bave trkama, neguju svoju strast prema automobilizmu i teže izvanrednim
                postignućima. Njihova posvećenost inovacijama, održivosti i društvenoj odgovornosti čini ih uzorom u trkačkom svetu. */}
            </div>
            <div className="col-11 m-auto mt-5 mb-5 ps-3 pe-3 sport-meet-text">
                <h5><FontAwesomeIcon className="me-2" icon={faTrophy} />Upoznajte neke od naših pobednika</h5>
            </div>
            <div className="row video-containers col-11 m-auto mb-5">
                <div className="video-container col-4">
                    <iframe
                        src={`https://www.youtube.com/embed/QQdoVepZAs8`}
                        title="YouTube Video"
                        allowFullScreen
                    ></iframe>
                </div>
                <div className="video-container col-4">
                    <iframe
                        src={`https://www.youtube.com/embed/QQdoVepZAs8`}
                        title="YouTube Video"
                        allowFullScreen
                    ></iframe>
                </div>
                <div className="video-container col-4">
                    <iframe
                        src={`https://www.youtube.com/embed/QQdoVepZAs8`}
                        title="YouTube Video"
                        allowFullScreen
                    ></iframe>
                </div>
            </div>
        </div>
    );
}

export default SportPage;
