import contactImg from "../assets/images/contact.png";
import locationImg from "../assets/images/location.png";
import phoneImg from "../assets/images/phone.png";
import mailImg from "../assets/images/mail.png";
import socialImg from "../assets/images/social.png";
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import Input from "../shared/components/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { useState } from "react";
import { useEffect } from "react";

const ContactPage = () => {

    const [content, setContent] = useState('');
    const [contentTitle, setContentTitle] = useState('');

    const getContent = async () => {
        const currentTime = new Date(new Date().getTime());
        localStorage.setItem('lastHttp', currentTime);
        try {
          const response = await fetch(
            "https://api.bagi.rs/api/v1/get/page/content",
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(
                    {
                        page: 'contact'
                    }
                )
            }
        );
    
        if (!response.ok) {
            throw new Error("Error updating data");
        }
    
          let updatedData = await response.json();
          setContent(updatedData?.data?.content ?? '');
          setContentTitle(updatedData?.data?.title ?? '');
        } catch (error) {
          console.log(error.message);
        }
    };

    useEffect(() => {
        getContent();
    }, []);

    const containerStyle = {
        width: '100%',
        height: '100%'
    };
      
    const center = {
        lat: 43.762128136620646,
        lng: 20.646504110462566
    };

    return (
        <div className="about-us-page">
            <div className="about-image">
                <img className="img-fluid" src={contactImg} alt="contac"></img>
                <h1>{contentTitle}</h1>
            </div>
            <div className="col-8 m-auto mt-5 d-flex justify-content-around flex-wrap contact-list">
                <div className="contact-item">
                    <img className="img-fluid" src={locationImg} alt="location"></img>
                    <a href="https://goo.gl/maps/vQncqWRN5vvGe7MN7" target="_blank" rel="noreferrer">
                        <p>Nikole Tesle 165A - 36000</p>
                        <p>Kraljevo Srbija</p>
                    </a>
                </div>
                <div className="contact-item">
                    <img className="img-fluid" src={phoneImg} alt="location"></img>
                    <p><a href="tel:+381 69 800 31 28">+381 69 800 31 28</a></p>
                    <p><a href="tel:+381 69 847 64 64"> +381 69 847 64 64</a></p>
                </div>
                <div className="contact-item">
                    <img className="img-fluid" src={mailImg} alt="location"></img>
                    <p><a href="mailto:info@bagi.rs">info@bagi.rs</a></p>
                </div>
                <div className="contact-item">
                    <img className="img-fluid" src={socialImg} alt="location"></img>
                    <p>
                        <a className="facebook me-2" href="https://www.facebook.com/bagitrans/?locale=sr_RS" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFacebook} /></a>
                        <a className="instagram me-2" href="https://www.instagram.com/bagi.trans/?hl=en https://www.facebook.com/bagitrans/?locale=sr_RS"
                            target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram} /></a>
                        <a className="youtube me-2" href="https://www.youtube.com/@dejanlazarevic6509" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faYoutube} /></a>
                    </p>
                </div>
            </div>
            <div className="col-8 m-auto mt-5 contact-text">
                {/* Imate li pitanja o oglasu ili kako funkcioniše kupovina i prodaja? Budite slobodni da nas kontaktirate koristeći formu
                ispod ili nas pozovite na +381 69 847 64 64. Otvoreni smo radnim danima od 8 do 16 časova i trudimo se da
                odgovorimo na sve poruke u roku od 24 sata neprazničnim radnim danima. */}
                <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
            <div className="col-12  m-auto mt-5 mb-5 contact-form">
                <div className="row m-0">
                    <div className="col-6 map-style">
                        <LoadScript googleMapsApiKey="AIzaSyCObgZMndrsSzTS_WIx5lRBfitpKeZlluQ">
                            <GoogleMap
                                mapContainerStyle={containerStyle}
                                center={center}
                                zoom={14}
                            >
                                <Marker position={center} />
                            </GoogleMap>
                        </LoadScript>
                    </div>
                    <div className="col-6">
                        <h5 className="contact-form-title">Pošalji poruku</h5>
                        <div className="row">
                            <div className="col-6">
                                <Input
                                    value={'mica'}
                                    isMulti={false}
                                    disabled={false}
                                    inputType="text-input"
                                    type="text"
                                    placeHolder="Ime"
                                />
                            </div>
                            <div className="col-6">
                                <Input
                                    value={'mica'}
                                    isMulti={false}
                                    disabled={false}
                                    inputType="text-input"
                                    type="text"
                                    placeHolder="Prezime"
                                />
                            </div>
                            <div className="col-6">
                                <Input
                                    value={'mica'}
                                    isMulti={false}
                                    disabled={false}
                                    inputType="text-input"
                                    type="text"
                                    placeHolder="Email"
                                />
                            </div>
                            <div className="col-6">
                                <Input
                                    value={'mica'}
                                    isMulti={false}
                                    disabled={false}
                                    inputType="text-input"
                                    type="text"
                                    placeHolder="Telefon"
                                />
                            </div>
                            <div className="col-12 mb-4">
                                <Input
                                    value={'mica'}
                                    isMulti={false}
                                    disabled={false}
                                    inputType="textarea"
                                    placeHolder="Poruka"
                                />
                            </div>
                        </div>
                        <button type="button" className="btn btn-outline-dark contact-form-button">Pošalji poruku</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactPage;
