export const currencyFormat = (num) => {
    return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(num);
}

export const doesIdExist = (array, id) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].id === id) {
        return true; // ID found
      }
    }
    return false; // ID not found
}