import { useContext } from "react";
import AuthContext from "../shared/contexs/auth-contex";
import { toast } from "react-toastify";
import FileManager from "../shared/components/FileManager/FileManager";

const DocumentsPage = () => {

  const { user } = useContext(AuthContext);

  const getFolders = async (path) => {
    const currentTime = new Date(new Date().getTime());
    localStorage.setItem('lastHttp', currentTime);
    try {
      const response = await fetch(
        "https://api.bagi.rs/api/v1/get/documents/content?disk=public&path=" + path, 
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          }
        }
      );

      if (!response.ok) {
        throw new Error("Error updating data");
      }
  
      let updatedData = await response.json();

      const combinedData = [...(updatedData?.directories || []), ...(updatedData?.files || [])];

      const mappedData = combinedData.map(item => {
        // Destructure the object to get 'basename' and the rest of the properties
        const { basename, type, ...rest } = item;
      
        // Set 'type' to 1 if it is a 'file'
        const updatedType = type === 'file' ? 1 : type;
      
        // Return a new object with 'name' property and updated 'type'
        return { ...rest, name: basename, type: updatedType };
      });

      return mappedData;
    } catch (error) {
      console.log(error.message);
    }
  };

  const downloadFile = async (path) => {
    const currentTime = new Date(new Date().getTime());
    localStorage.setItem('lastHttp', currentTime);

    if (!user?.access_token) {
      toast.warning('Morate biti ulogovani!');
      return
    }
  
    try {
      const response = await fetch(
        `https://api.bagi.rs/api/v1/get/documents/download?disk=public&path=${path}`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${user?.access_token}`
          }
        }
      );

      if (!response.ok) {
        debugger
        throw new Error("Error updating data");
      }
  
      const filename = path.split('/').pop(); // Extract filename from path
  
      const blob = await response.blob();

      const url = URL.createObjectURL(blob);
  
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
  
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

    } catch (error) {
      toast.error(error.message || 'Something went wrong!');
    }
  };
      
      

  return (
    <div className="about-us-page documents-page">
      <div className="about-image"></div>
      <div className="col-8 m-auto mt-5 mb-5">
        <FileManager
          getList={getFolders}
          openFile={downloadFile}
        />
      </div>
    </div>
  );
}

export default DocumentsPage;

{/* <FileManager
getList={getFolders}
// createDirectory={createDirectory}
// deletePaths={deletePaths}
openFile={downloadFile}
// uploadFiles={uploadFiles}
// rename={rename}
// features={['createDirectory', 'uploadFiles', 'deletePaths', 'rename']}
/> */}
