import bagisImg from "../assets/images/bagis.jpg";

const NotFoundPage = () => {

    return (
        <div className="about-us-page">
            <div className="about-image">
                <img className="img-fluid" src={bagisImg} alt="sport"></img>
            </div>
            <div className="col-8 m-auto mt-5 mb-5">
            </div>
        </div>
    );
}

export default NotFoundPage;
