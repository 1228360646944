import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import ImagesSlider from "../components/ImagesSlider";
import { currencyFormat } from "../shared/helpers/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faViber, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { useContext } from "react";
import AuthContext from "../shared/contexs/auth-contex";
import { faFile, faFilePdf, faShareAltSquare } from "@fortawesome/free-solid-svg-icons";
import logo from "../assets/images/logo.png";

const ProductDetailsPage = () => {

  let { prdId } = useParams();
  const [productDetails, setProductDetails] = useState({});
  const { user } = useContext(AuthContext);

  const waMessage = () => {
    const url = `https://wa.me/+381698003128?text=${encodeURIComponent('Poštovani, Interesuje me ' + (productDetails?.manufacturer_name ?? '') + ' ' + (productDetails?.model_name ?? '') + (productDetails?.tablica_name ? (' (' + productDetails?.tablica_name + ')') : '') + ' iz Vaše ponude. Hvala')}`;
    window.open(url, '_blank');
  };

  const viberMessage = () => {
    const url = `viber://chat?number=+381698003128&draft=${'Poštovani, Interesuje me ' + (productDetails?.manufacturer_name ?? '') + ' ' + (productDetails?.model_name ?? '') + (productDetails?.tablica_name ? (' (' + productDetails?.tablica_name + ')') : '') + ' iz Vaše ponude. Hvala'}`;
    window.open(url, '_blank');
  };

  const getProductDetils = async (id) => {
    const currentTime = new Date(new Date().getTime());
    localStorage.setItem('lastHttp', currentTime);
    try {
      const response = await fetch(
        "https://api.bagi.rs/api/v1/get/vehicle",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${user?.access_token}`
          },
          body: JSON.stringify(
            {
              vehicle_id: id
            }
          )
        }
      );

      if (!response.ok) {
        throw new Error("Error updating data");
      }

      let updatedData = await response.json();
      setProductDetails(updatedData?.data?.vehicle ?? {});
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getProductDetils(prdId);
  }, [prdId, user?.access_token]);

  const handleDownload = async (fileUrl, fileName) => {
    try {
      const response = await fetch(
        "https://pdfclown.files.wordpress.com/2011/04/texthighlight.jpg"
      );
      const blob = await response.blob();

      const url = URL.createObjectURL(blob);
  
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
  
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const handleShare = async () => {
    try {
      if (navigator.share) {
        updateMetaTags(productDetails);
        await navigator.share({
          title: (productDetails?.manufacturer_name ?? '') + ' ' + (productDetails?.model_name ?? ''),
          text: '',
          url: window.location.href,
        });
      } else {
        throw new Error('Web Share API nije podržan.');
      }
    } catch (error) {
      console.error('Greška pri deljenju:', error.message);
    }
  };

  const updateMetaTags = (productDetails) => {
    // Update meta tags dynamically based on product details
    const ogTitle = `${productDetails?.manufacturer_name ?? ''} ${productDetails?.model_name ?? ''}`;
    const ogDescription = `${productDetails?.description ?? ''}`;
    const ogImage = productDetails?.images?.[0]?.image_url ?? logo;
    const ogUrl = window.location.href;

    // Remove existing meta tags
    removeMetaTags();

    // Create and append new meta tags
    createMetaTag('og:title', ogTitle);
    createMetaTag('og:description', ogDescription);
    createMetaTag('og:image', ogImage);
    createMetaTag('og:url', ogUrl);
  };

  const createMetaTag = (property, content) => {
    const metaTag = document.createElement('meta');
    metaTag.setAttribute('property', property);
    metaTag.setAttribute('content', content);
    document.head.appendChild(metaTag);
  };

  const removeMetaTags = () => {
    const existingMetaTags = document.querySelectorAll('meta[property^="og:"]');
    existingMetaTags.forEach(tag => tag.remove());
  };

  return (
    <div className="product-details-page">
        <div className="row product-details-page-row container">
          <div className="col-12 mb-4">
            <span className="square"></span>
            <h1>{(productDetails?.manufacturer_name ?? '') + ' ' + (productDetails?.model_name ?? '')}</h1>
          </div>
        </div>
        <div className="container">
            <div className="row">
                <div className="col-7 pe-3">
                  <ImagesSlider images={productDetails?.images ?? []} />
                  <div className="product-social d-flex justify-content-between">
                    <div>
                      <FontAwesomeIcon icon={faWhatsapp} className="me-2 whatapp-svg" onClick={waMessage} />
                      <FontAwesomeIcon icon={faViber} className="me-2 viber-svg" onClick={viberMessage} />
                    </div>
                    {navigator.share && (
                      <FontAwesomeIcon icon={faShareAltSquare} className="me-2 share-svg" onClick={handleShare} />
                    )}
                  </div>
                </div>
                <div className="col-5 ps-3">
                  <div className="product-details-info-container">
                    <div className="product-details-info-header">
                      <h5>Detalji</h5>
                    </div>
                    <div className="product-details-info-body">
                      <div className="row">
                        <div className="col-6 text-body-left">
                          <h5>Kategorija</h5>
                        </div>
                        <div className="col-6 text-body-right">
                          {productDetails?.category_name ?? '-'}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 text-body-left">
                          <h5>Cena[EUR]</h5>
                        </div>
                        <div className="col-6 text-body-right price-details-color">
                          {productDetails?.model_name ? ((productDetails?.show_price && productDetails?.price > 0) ? currencyFormat(productDetails?.price ?? 0) + " €" : 'Kontaktirajte nas') : '-'}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 text-body-left">
                          <h5>Proizvođač</h5>
                        </div>
                        <div className="col-6 text-body-right">
                          {productDetails?.manufacturer_name ?? '-'}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 text-body-left">
                          <h5>Model</h5>
                        </div>
                        <div className="col-6 text-body-right">
                          {productDetails?.model_name ?? '-'}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 text-body-left">
                          <h5>Godina proizvodnje</h5>
                        </div>
                        <div className="col-6 text-body-right">
                          {productDetails?.production_year_c ?? '-'}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 text-body-left">
                          <h5>Radni sati</h5>
                        </div>
                        <div className="col-6 text-body-right">
                          {productDetails?.machine_working_hours_c ?? '-'}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 text-body-left">
                          <h5>Snaga[KW]</h5>
                        </div>
                        <div className="col-6 text-body-right">
                          {productDetails?.machine_engine_power_c ?? '-'}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 text-body-left">
                          <h5>Zapremina[cm3]</h5>
                        </div>
                        <div className="col-6 text-body-right">
                          {productDetails?.machine_engine_volume_c ?? '-'}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 text-body-left">
                          <h5>Dimenizija[m](DxŠxV)</h5>
                        </div>
                        <div className="col-6 text-body-right">
                          {productDetails?.dimensions ?? '-'}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 text-body-left">
                          <h5>Težina</h5>
                        </div>
                        <div className="col-6 text-body-right">
                          {productDetails?.machine_weight_c ?? '-'}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 text-body-left">
                          <h5>Boja</h5>
                        </div>
                        <div className="col-6 text-body-right">
                          {productDetails?.machine_color_c ?? '-'}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 text-body-left">
                          <h5>Broj tablice</h5>
                        </div>
                        <div className="col-6 text-body-right">
                          {productDetails?.tablica_name ?? '-'}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 text-body-left">
                          <h5>Broj šasije</h5>
                        </div>
                        <div className={ user?.access_token ? "col-6 text-body-right" : "col-6 text-body-right red-primary"}>
                          {user?.access_token ? (productDetails?.chassis_number_c ?? '-') : 'Samo za registovane korisnike'}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 text-body-left">
                          <h5>Broj motora</h5>
                        </div>
                        <div className={ user?.access_token ? "col-6 text-body-right" : "col-6 text-body-right red-primary"}>
                          {user?.access_token ? (productDetails?.machine_engine_code_c ?? '-') : 'Samo za registovane korisnike'}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 text-body-left">
                          <h5>Registarska oznaka</h5>
                        </div>
                        <div className={ user?.access_token ? "col-6 text-body-right" : "col-6 text-body-right red-primary"}>
                          {user?.access_token ? (productDetails?.vehicle_registry_code_c ?? '-') : 'Samo za registovane korisnike'}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 mb-4 mt-4">
                  <div className="product-details-info-header">
                    <h5>Opis</h5>
                  </div>
                  <p>{productDetails?.description ?? ''}</p>
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col-12 col-sm-6 video-details">
                      <iframe
                        src={productDetails?.youtube_video ? `https://www.youtube.com/embed/` + productDetails?.youtube_video : ''}
                        title="YouTube Video"
                        allowFullScreen
                      ></iframe>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="files-details">
                        <p>Info dokument</p>
                        {productDetails?.documents?.length > 0 && productDetails?.documents?.map((document, index) => {  
                          return (
                            <a
                              key={index}
                              className="document-element-details"
                              href={document?.file_url ?? ''}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FontAwesomeIcon icon={document.file_mime_type.includes('pdf') ? faFilePdf : faFile} className="me-2 pdf-details" />
                              {document.file_name ?? ''}
                            </a>
                            // <div className="document-element-details" key={index} onClick={() => handleDownload(document.file_url, document.file_name)}>
                            //   <FontAwesomeIcon icon={faFilePdf} className="me-2 pdf-details"/>
                            //   {document.file_name ?? ''}
                            // </div>
                            // <a href={'C:/Users/Asus/Downloads/Archive/Detalji.pdf'} download={true}>
                            //   <FontAwesomeIcon icon={faFilePdf} className="me-2 pdf-details" target="_blank"/>
                            //   {document.file_name ?? ''}
                            // </a>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default ProductDetailsPage;
