import { Modal } from "react-bootstrap";
import { useContext, useState } from "react";
import Input from "../shared/components/Input";
import useInput from "../shared/hooks/use-input";
import AuthContext from "../shared/contexs/auth-contex";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";

const LoginRegisterModal = ({ openModal, handleClose }) => {

    const [loginSelecter, setLoginSelecter] = useState(true);
    const { login } = useContext(AuthContext);
    const regax = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    const [isLoading, setIsLoading] = useState(false);

    const {
      value: nameValue,
      isValid: nameIsValid,
      hasError: nameHasError,
      valueChangeHandler: nameChangeHandler,
      inputBlurHandler: nameBlurHandler,
      reset: resetName
    } = useInput((value) => value.trim() !== '');

    const {
      value: emailValue,
      isValid: emailIsValid,
      hasError: emailHasError,
      valueChangeHandler: emailChangeHandler,
      inputBlurHandler: emailBlurHandler,
      reset: resetEmail
    } = useInput((value) => (!value && value.trim() !== '') || regax.test(value) !== false);

    const {
      value: passwordValue,
      isValid: passwordIsValid,
      hasError: passwordHasError,
      valueChangeHandler: passwordChangeHandler,
      inputBlurHandler: passwordBlurHandler,
      reset: resetPassword
    } = useInput((value) => value.trim() !== '');

    const loginService = async (requestData) => {
      setIsLoading(true);
      try {
        const response = await fetch(
          "https://api.bagi.rs/api/v1/bagi-backoffice/login",
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(
              {
                email: requestData.email,
                password: requestData.password
              }
            )
          }
        );
  
        let updatedData = await response.json();

        if (updatedData.is_error) {
          throw new Error(updatedData.message ?? 'Greška prilikom prijavljivanja!');
        }

        toast.success(updatedData.message ?? 'Uspešno ste se prijavili!');
        setIsLoading(false);
        return updatedData?.data ?? {};
      } catch (error) {
        toast.warning(error.message || 'Something went wrong!');
        setIsLoading(false);
      }
    };

    const registerService = async (requestData) => {
      try {
        const response = await fetch(
          "https://api.bagi.rs/api/v1/bagi-backoffice/register",
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(
              {
                full_name: requestData.name,
                email: requestData.email,
                password: requestData.password,
                password_confirmation: requestData.password
              }
            )
          }
        );
  
        let updatedData = await response.json();

        if (updatedData.is_error) {
          throw new Error(updatedData.message ?? 'Greška prilikom registracije!');
        }

        toast.success(updatedData.message ?? 'Uspešno ste se registrovali!');
        return updatedData?.data ?? {};
      } catch (error) {
        toast.warning(error.message || 'Something went wrong!');
      }
    };

    const submitHandler = async (event) => {
        event.preventDefault();
        if (loginSelecter) {
            const requestData = {
              email: emailValue,
              password: passwordValue
            };
          
            const data = await loginService(requestData);
            if (data) {
              const decoded = jwt_decode(data.access_token);
              const expirationTime = new Date(decoded.exp * 1000);
              login(data, expirationTime);
              resetEmail();
              resetPassword();
              handleClose();
            };
        } else {
            const requestData = {
              name: nameValue,
              email: emailValue,
              password: passwordValue
            };
          
            const data = await registerService(requestData);
            if (data) {
              resetName();
              resetEmail();
              resetPassword();
              setLoginSelecter(true);
            };
        }
    };

    return (
        <Modal
          show={openModal}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
          scrollable={true}
          className="login-register-modal"
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-xl-12">
                        {loginSelecter && (
                            <form onSubmit={submitHandler} className="login-form">
                                <h4 className="bolder">Prijavite se</h4>
                                <Input
                                    inputValue={emailValue}
                                    onInputChange={emailChangeHandler}
                                    disabled={false}
                                    inputType="input"
                                    type="text"
                                    class="form-control login-form-control mt-4"
                                    placeHolder="Email"
                                />
                                <Input
                                    inputValue={passwordValue}
                                    onInputChange={passwordChangeHandler}
                                    disabled={false}
                                    inputType="input"
                                    type="password"
                                    class="form-control login-form-control mt-4"
                                    placeHolder="Šifra"
                                />
                                <div className="d-flex align-items-center justify-content-center mt-3">
                                    <p className="login-text">Nemate nalog?</p>
                                    <button className="btn register-button" type="button" onClick={() => setLoginSelecter(false)}>
                                      Registrujte se
                                    </button>
                                </div>
                                <button className="btn login-button d-inline mt-4 w-100" type="submit">
                                  Prijavite se
                                </button>
                            </form>
                        )}
                        {!loginSelecter && (
                            <form onSubmit={submitHandler} className="login-form">
                                <h4 className="bolder">Registrujte se</h4>
                                <Input
                                    inputValue={nameValue}
                                    onInputChange={nameChangeHandler}
                                    disabled={false}
                                    inputType="input"
                                    type="text"
                                    class="form-control login-form-control mt-4"
                                    placeHolder="Ime i prezime"
                                />
                                <Input
                                    inputValue={emailValue}
                                    onInputChange={emailChangeHandler}
                                    disabled={false}
                                    inputType="input"
                                    type="text"
                                    class="form-control login-form-control mt-4"
                                    placeHolder="Email"
                                    offAutoComplete={true}
                                />
                                <Input
                                    inputValue={passwordValue}
                                    onInputChange={passwordChangeHandler}
                                    disabled={false}
                                    inputType="input"
                                    type="password"
                                    class="form-control login-form-control mt-4"
                                    placeHolder="Šifra"
                                    offAutoComplete={true}
                                />
                                <div className="d-flex align-items-center justify-content-center mt-3">
                                    <p className="login-text">Imate nalog?</p>
                                    <button className="btn register-button" type="button" onClick={() => setLoginSelecter(true)}>
                                      Prijavite se
                                    </button>
                                </div>
                                <button className="btn login-button d-inline mt-4 w-100" type="submit">
                                  Registrujte se
                                </button>
                            </form>
                        )}
                    </div>
                </div>
            </Modal.Body>
            {isLoading && (
              <div id="pause" className="d-flex align-items-center justify-content-center">
                <div id="spinner"></div>
              </div>
            )}
        </Modal>
    );
}
  
export default LoginRegisterModal;